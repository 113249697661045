/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-28 14:03:44
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-03-30 20:57:39
 * @FilePath: \application-examinee-frontend\src\request\model\user\Verification.ts
 * @Description: 获取手机/邮箱验证码，获取图片验证码，各种验证码的接口数据模型
 */

/**
 * @description: 图片验证码
 */
export namespace ImageCaptcha {
	export interface Post {}

	export type Response = string;
}

/**
 * @description: 手机验证码
 */
export namespace PhoneCaptcha {
	export interface Post {
        phone: string;
    }

	export interface Response {
		message: string;
		code: string;
	};
}

/**
 * @description: 邮箱验证码
 */
export namespace EmailCaptcha {
	export interface Post {
        email: string;
    }

	export interface Response {
		message: string;
		code: string;
	};
}

/**
 * @description: 找回密码手机验证码
 */
export namespace PwPhoneCaptcha {
	export interface Post {
        phone: string;
    }

	export interface Response {
		message: string;
		code: string;
	};
}

/**
 * @description: 找回密码邮箱验证码
 */
export namespace PwEmailCaptcha {
	export interface Post {
        email: string;
    }

	export interface Response {
		message: string;
		code: string;
	};
}