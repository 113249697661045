/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 14:57:14
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-30 15:44:10
 * @FilePath: \application-examinee-frontend\src\request\model\announcement\List.ts
 * @Description: 公告列表
 */

export namespace BlogList {

	export type BlogType = '通知公告' | '政策制度' | '问题解答';

	export enum Columns {
		// id
		ID = "id",
		// 标题
		TITLE = "title",
		// 博客类型
		TYPE = "type",
		IS_PUBLISHED = "is_published",
		RELEASE_TIME = "release_time",
		CREATED_TIME = "created_time",
		UPDATED_TIME = "updated_time"
	}

	export interface Post {
		blog_name?: string;
		blog_type?: BlogType;
		page_num?: number;
		page_size?: number;
	}

	export interface BlogItem {
		[Columns.ID]: number;
		[Columns.TITLE]: string;
		[Columns.TYPE]: string;
		[Columns.IS_PUBLISHED]: boolean;
		[Columns.RELEASE_TIME]: string;
		[Columns.CREATED_TIME]: string;
		[Columns.UPDATED_TIME]: string;
	}

	export interface Response {
		data: BlogItem[];
		page: {
			total: number;
			page_num: number;
			page_size: number;
			pages: number;
		}
	}
}
