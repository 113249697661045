/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 13:54:52
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-01-04 10:11:12
 * @FilePath: \application-examinee-frontend\src\hooks\useUserInfo.ts
 * @Description: 获取用户信息
 */

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDetail, user as userDetail } from '@/store/user.slice';

import { PersonalInfo } from '@model';
import { getPersonalInfo } from '@actions';

const useUserInfo = (refreshKey?: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const store: { detail?: Partial<PersonalInfo.Response> } = useSelector(userDetail);
    const [loading, updateLoading] = useState(false);

    /**
     * @description: 全局挂载用户信息
     * @param {any} values
     * @return {*}
     */
    const dispatchUserInfo = (values: any) => {
        dispatch(updateUserDetail(values));
    };

    /**
     * @description: 获取用户信息
     * @param {PersonalInfo} values
     * @return {*}
     */
    const getUserInfoTask = async (values: PersonalInfo.Post) => {
        try {
            updateLoading(true);
            const res = await getPersonalInfo(values);
            dispatchUserInfo(res);
        }
        catch {
            dispatch(updateUserDetail(null));
        }
        finally {
            updateLoading(false)
        }
    };

    /**
     * @description: 登出
     * @return {*}
     */
    const logout = (path?: string) => {
        localStorage.setItem('access_token', '');
        dispatchUserInfo(null);
        history.replace(path ?? '/login');
    };

    useEffect(() => {
        !!refreshKey && getUserInfoTask({});
    }, [refreshKey]);

    return {
        loading,
        user: store?.detail,
        getUserInfoTask,
        dispatchUserInfo,
        logout
    };
};

export default useUserInfo;