/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-30 15:26:18
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-01-04 10:12:49
 * @FilePath: \application-examinee-frontend\src\request\model\blog\detail.ts
 * @Description: 公告详情
 */

export namespace BlogDetail {

    export enum Columns {
        // id
        ID = 'id',
        // 标题
        TITLE = 'title',
        // 富文本内容
        CONTENT = 'content',
        // 下载目录
        ATTACHMENTS = 'attachments',
        IS_PUBLISHED = 'is_published',
        // 发布时间
        RELEASE_TIME = 'release_time',
        CREATED_TIME = 'created_time',
        UPDATED_TIME = 'updated_time'
    }

    export interface Post {
        blog_id?: string;
    }

    export interface ATTACHMENT_ITEM {
        name: string;
        url: string;
    }

    export interface Response {
        [Columns.ID]: number;
        [Columns.TITLE]: string;
        [Columns.CONTENT]?: any[];
        [Columns.ATTACHMENTS]: ATTACHMENT_ITEM[];

        [Columns.IS_PUBLISHED]: number;
        [Columns.RELEASE_TIME]: string;
        [Columns.CREATED_TIME]: string;
        [Columns.UPDATED_TIME]: string;
    }
}
