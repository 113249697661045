
import request from '../service';
import { examsList, examRoom, application, applications, invoices, invoice, refund } from '@api';

// 获取考试列表
export const getEaxamsListAction = (params: any) =>
    request.get(`${examsList}`, { params });
    
// 获取区域
export const getEaxamsRoomsAction = (params: any) =>
    request.get(`${examRoom}/${params?.examId}/city`, {});

// 报名
export const postApplication = (params: any) =>
    request.post(`${application}`, { ...params });
    
// 获取已报名的考试
export const getApplications = (params: any) =>
    request.get(`${applications}`, { params });


//退款
export const putRefund = (params: any) => request.put(`${application}/${params?.applicationId}/cancel`, {});

// 改期
export const putChange = (params: any) => request.put(`${application}/${params?.applicationId}/change`, {});
// 获取考试信息
// export const getApplicationInfo = (params: any) =>
// request.get(`${application}/${params?.applicationId}/user_info`, params.values);

// 更新某个考试的考生信息
export const editApplyPersonalInfo = (params: any) =>
    request.put(`${application}/${params?.applicationId}/examinee_info`, params.values);

// 获取付款信息
export const getApplyInfoByExamId = (params: any) =>
request.get(`${examRoom}/${params?.examId}/application`, params.values);
    // service/exam/2/application

// Request<PersonalEdit.Post, PersonalInfo.Response>
// = params => request.put(personalEdit, params);

// 获取发票列表
export const getInvoices = (params: any) =>
    request.get(`${invoices}`, { params });

// 获取发票详情
export const getInvoicesInfo = (params: any) => request.get(`${invoice}/${params?.applicationId}`, {});


export const updateInvoicesInfo = (params: any) =>
    request.put(`${invoice}/${params?.applicationId}`, params.values);


export const getPaymentInfo = (params: any, cancelToken: any) => {
    return request({
        url: `${application}/${params?.applicationId}/payment`,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params,
        cancelToken
    });
    // return request.put(`${application}/${params?.applicationId}/payment`, {}, {cancelToken: cancelToken});
}