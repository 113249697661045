/* eslint-disable max-len */
/**
 * @file 系统的文案
 * @author jianghaoran01
 * @description 所有的文案统一放此处管理
 */
const zhCN = {
    translation: {
        '系统title': '华测在线-CPBA考试预约系统'
    }
};

export default zhCN;
