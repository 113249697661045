/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-30 10:16:32
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-03-10 15:52:56
 * @FilePath: \application-examinee-frontend\src\pages\home\hooks\useGetBackPsw.tsx
 * @Description: 找回密码弹窗
 */

import React, { useMemo, useState } from 'react';
import moment from 'moment';

import { Modal } from 'antd';

import { PersonalEdit } from '@model';

import styles from './index.module.less';
import PersonalViewForm from '../../components/personal-view-form';
import TitleBar from '@/pages/apply/components/title-bar';

export type FormValues = Omit<PersonalEdit.Post, 'birth_date' | 'address_province' | 'address_city'> & {
    birth_date: moment.Moment,
    address: {
        province: string;
        city: string;
    }
};

export default ({
    onCancel,
    onOk,
    submitTask
}: {
    onCancel?: () => void;
    onOk?: () => void;
    submitTask?: (values: FormValues) => Promise<unknown>;
}) => {
    const [visible, updateVisible] = useState(false);
    const [initValues, updateInitValues] = useState<FormValues>();

    /**
     * @description: 展示弹窗
     * @return {*}
     */
    const showModal = (values?: FormValues) => {
        values && updateInitValues(initValues);
        updateVisible(true);
    };

    /**
     * @description: 返回
     * @return {*}
     */
    const handleBack = () => {
        initValues && updateInitValues(null);
        updateVisible(false);
        onCancel?.();
    };

    const modalRenderer = useMemo(() => (
        <Modal
            className={styles.container}
            open={visible}
            closable={false}
            onCancel={handleBack}
            footer={false}
            centered
            width={600}
            destroyOnClose
        >
            <div className={styles.container}>
                <TitleBar title='个人信息' />
                <PersonalViewForm />
            </div>
        </Modal>
    ), [visible]);

    return {
        modalRenderer,
        showModal
    };
};
