/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-26 14:33:11
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-04-10 20:39:01
 * @FilePath: \application-examinee-frontend\src\layout\layout-header\index.jsx
 * @Description: 页面头部
 */

import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { map } from 'lodash';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar, Dropdown } from 'antd';
import notification from '@/components/notification';

import layoutConfig, { LayoutConfigItem } from '@/configure/layout';
import useScrollTop from '@/hooks/useScrollTop';
import useUserInfo from '@/hooks/useUserInfo';
import usePersonalInfoViewModal from './hooks/usePersonalInfoViewModal';

import styles from './index.module.less';
import { blackList } from '@/configure/routes';

const LayoutHeader = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const route = useRouteMatch();
    const pathname = window.location.pathname;
    const { user, logout } = useUserInfo(route);
    useScrollTop();
    const { modalRenderer, showModal } = usePersonalInfoViewModal({});

    useEffect(() => {
        if (user?.info_required) {
            const inRedirectList = [...blackList, '/personal-info-edit'].find(path => pathname.startsWith(path));
            if (inRedirectList) {
                return;
            }
            notification.warning({
                message: '暂无个人信息，请完善'
            });
            history.replace('/personal-info-edit');
        }
    }, [user]);

    /**
     * @description: 菜单渲染函数
     * @param {*} title 标题
     * @param {*} path 跳转路由
     * @return {*}
     */
    const renderMenuItem = ({ title, path, beforeRender }: LayoutConfigItem) => {
        if (beforeRender && !beforeRender?.(user)) {
            return;
        }
        return (
            <div
                key={path}
                className={cls(
                    styles.menu,
                    {
                        [styles.active]: pathname.startsWith(path)
                    }
                )}
                onClick={() => history.push(path)}
            >
                {title}
            </div>
        );
    };

    // 左侧标题以及菜单栏
    const leftRenderer = useMemo(() => (
        <div className={styles.left}>
            <div className={styles.title} onClick={() => history.push(user ? '/' : '/login')}>
                <p>{t('系统title')}</p>
            </div>
            <div className={styles['menu-wrapper']}>
                {map(layoutConfig, renderMenuItem)}
            </div>
        </div>
    ), [route, user]);

    // 右侧用户大头照以及用户名
    const rightRenderer = useMemo(() => {
        const needLogin = !user?.name && !user?.username;
        const inner = (
            <div className={styles.right} onClick={() => needLogin && history.push('/login')}>
                <Avatar className={styles.avatar} src={user?.show_photo} />
                <div className={styles.username}>{user?.name ?? user?.username ?? '请登录'}</div>
            </div>
        );
        if (!user) {
            return inner;
        }
        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: 'personal-info-view',
                            label: '个人信息',
                            onClick: () => showModal()
                        },
                        {
                            key: 'change-psw',
                            label: '修改密码',
                            onClick: () => history.push('/password')
                        },
                        {
                            key: 'logout',
                            label: '退出登录',
                            onClick: () => logout()
                        }
                    ]
                }}
                overlayClassName={styles.dropdown}
                className={styles.dropdown}
                getPopupContainer={el => el}
            >
                {inner}
            </Dropdown>
        );
    }, [user]);

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {leftRenderer}
                <div className={styles['right-wrapper']}>
                    <div className={styles.about}>
                        客服电话：010-57278010&nbsp;&nbsp;
                        客服邮箱：operation@chiest.cn
                    </div>
                    {rightRenderer}
                </div>
            </div>
            {modalRenderer}
        </div>
    );
};

export default LayoutHeader;