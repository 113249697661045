/**
 * @file 状态收口文件
 * @author jianghaoran01
 */

import {configureStore} from '@reduxjs/toolkit';
import {userSlice} from './user.slice.js';
import {invigilateSlice} from './invigilate.slice.js';
import {applySlice} from './apply.slice.js';

export const rootReducer = {
    userSlice: userSlice.reducer,
    invigilateSlice: invigilateSlice.reducer,
    applySlice: applySlice.reducer
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
});
