/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-26 14:44:34
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-03-01 10:28:22
 * @FilePath: \application-examinee-frontend\src\configure\layout\index.js
 * @Description: 主菜单配置
 */

export type LayoutConfigItem = {
    path: string;
    title: string;
    beforeRender?: (user: any) => boolean;
};

const layoutConfig: LayoutConfigItem[] = [
    // {
    //     path: '/blog/notice',
    //     title: '通知公告'
    // },
    // {
    //     path: '/blog/policy',
    //     title: '政策制度'
    // },
    // {
    //     path: '/blog/qa',
    //     title: '问题解答'
    // },
    // {
    //     path: '/apply',
    //     beforeRender: user => !!user,
    //     title: '我要报名'
    // }
];

export default layoutConfig;
