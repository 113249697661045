/*
 * @Author: jianghaoran01 13478707150@163.com
 * @Date: 2022-12-26 14:22:46
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-03-31 19:18:56
 * @FilePath: \application-examinee-frontend\src\layout\primary-layout\index.tsx
 * @Description: 基础布局
 */

import React, { useMemo } from 'react';

import { Layout } from 'antd';
import LayoutHeader from '../layout-header';
import LayoutMain from '../layout-main';

import { hiddenHeaderList } from '@/configure/routes';

import styles from './index.module.less';

const PrimaryLayout = () => {

    const hiddenHeader = useMemo(() => {
        const pathname = window.location.pathname;
        return hiddenHeaderList.includes(pathname);
    }, [window.location.pathname]);

    return (
        <Layout className={styles.container}>
            {!hiddenHeader && (<LayoutHeader />)}
            <div className={styles.content} id='layout-main'>
                <LayoutMain />
            </div>
        </Layout>
    );
};

export default PrimaryLayout;
