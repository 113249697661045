/*
 * @Author: JC 13478707150@163.com
 * @Date: 2023-01-07 08:33:42
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-01-07 08:45:50
 * @FilePath: \application-examinee-frontend\src\pages\apply\components\title-bar\index.tsx
 * @Description: 标题工具栏
 */

import React from 'react';
import cls from 'classnames';

import styles from './index.module.less';
import CusButton from '@/components/cus-button';

interface TitleBarProps {
    className?: string;
    title?: string;
    btnText?: string;
    withBtn?: boolean;
    onBtnClick?: () => void;
};

const TitleBar: React.FC<TitleBarProps> = ({
    className,
    title,
    btnText,
    withBtn = false,
    onBtnClick
}) => {
    const prefix = cls(styles.container, className);

    return (
        <div className={prefix}>
            <div className={styles.title}>{title}</div>
            {withBtn && (
                <CusButton className={styles.btn} onClick={onBtnClick}>{btnText}</CusButton>
            )}
        </div>
    );
};

export default TitleBar;