/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-28 09:39:33
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-03-29 10:08:32
 * @FilePath: \application-examinee-frontend\src\configure\routes\user.ts
 * @Description: 用户相关路由页面
 */

import React from 'react';

const userRoutes = [
    {
        path: '/register',
        exact: true,
        component: React.lazy(() => import('@/pages/register'))
    },
    {
        path: '/register-result',
        exact: true,
        component: React.lazy(() => import('@/pages/register-result'))
    },
    {
        path: '/personal-info-edit',
        exact: true,
        component: React.lazy(() => import('@/pages/personal-info-edit'))
    },
    {
        path: '/password',
        exact: true,
        component: React.lazy(() => import('@/pages/password'))
    }
];

export default userRoutes;
