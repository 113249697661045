/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-26 14:22:46
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-03-01 09:40:58
 * @FilePath: \application-examinee-frontend\src\configure\routes\user.js
 * @Description: 路由配置文件
 */

import React from 'react';

const dashboardRoutes = [
    {
        path: '/login',
        exact: true,
        component: React.lazy(() => import('@/pages/login'))
    },
    // {
    //     path: '/blog/:type(notice|policy|qa)',
    //     exact: true,
    //     component: React.lazy(() => import('@/pages/blog'))
    // },
    // {
    //     path: '/blog/:type(notice|policy|qa)/:id',
    //     exact: true,
    //     component: React.lazy(() => import('@/pages/blog-detail'))
    // },
    {
        path: '/privacy',
        exact: true,
        component: React.lazy(() => import('@/pages/privacy'))
    },
    {
        path: '/apply',
        exact: true,
        component: React.lazy(() => import('@/pages/apply'))
    },
];

export type BlogType = 'notice' | 'policy' | 'qa';

export default dashboardRoutes;
