/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 11:04:46
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-01-04 10:03:22
 * @FilePath: \application-examinee-frontend\src\request\model\user\personalInfo.ts
 * @Description: 个人信息
 */

export namespace PersonalInfo {

	export enum Columns {
		// 姓名
		NAME = 'name',
		// 性别
		GENDER = 'gender',
		// 国籍
		COUNTRY = 'country',
		// 出生日期
		BIRTH_DATA = 'birth_date',
		// 手机号码
		PHONE = 'phone',
		// 电子邮箱
		EMAIL = 'email',
		// 证件类型
		CERTIFICATE_TYPE = 'certificate_type',
		// 证件号码
		CERTIFICATE_ID = 'certificate_id',
		// 省
		ADDRESS_PROVINCE = 'address_province',
		// 市
		ADDRESS_CITY = 'address_city',
		// 详细地址
		ADDRESS_DETAIL = 'address_detail',
		// 姓
		PINYIN_LAST_NAME = 'pinyin_last_name',
		// 名
		PINYIN_FIRST_NAME = 'pinyin_first_name',
		// 大头照
		PHOTO = 'show_photo',
		SERVICE_PHTOTO = 'photo',
		// 用户id
		ID = 'id',
		CREATED_TIME = 'created_time',
		UPDATED_TIME = 'updated_time',

		// 用户是否需要完善个人信息
		INFO_REQUIRED = 'info_required',
		// 注册时提供的手机号
		USERNAME = 'username',
	}

	export interface Post {
	}

	// 完善基本信息后返回的数据结构
	export interface FullFieldRes {
		[Columns.NAME]?: string;
		[Columns.GENDER]?: string;
		[Columns.COUNTRY]?: string;
		[Columns.BIRTH_DATA]?: string;
		[Columns.PHONE]?: string;
		[Columns.EMAIL]?: string;
		[Columns.CERTIFICATE_TYPE]?: string;
		[Columns.CERTIFICATE_ID]?: string;
		[Columns.ADDRESS_PROVINCE]?: string;
		[Columns.ADDRESS_CITY]?: string;
		[Columns.ADDRESS_DETAIL]?: string;
		[Columns.PINYIN_LAST_NAME]?: string;
		[Columns.PINYIN_FIRST_NAME]?: string;
		[Columns.PHOTO]?: string;
		[Columns.SERVICE_PHTOTO]?: string;
		[Columns.ID]?: string;
		[Columns.CREATED_TIME]?: string;
		[Columns.UPDATED_TIME]?: string;
	}

	// 以下是未完善基本信息时返回的字段
	export interface UnFullFieldRes {
		[Columns.INFO_REQUIRED]?: boolean;
		[Columns.USERNAME]?: string;
	}

	// 完善前后返回的数据结构不相同
	export type Response = FullFieldRes & UnFullFieldRes;
}
