/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 10:34:08
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-04-07 16:27:29
 * @FilePath: \application-examinee-frontend\src\request\util.ts
 * @Description: 请求服务工具包
 */

import notification from '@/components/notification';
import axios from 'axios';
import { get } from 'lodash';
import { blackList as redirectBlackList } from '@/configure/routes';

export const baseURL = 'https://api-test.chiest.com/cpba-examinee/service';
// export const baseURL = 'https://api.chiest.com/cpba-examinee/service';
export const localUrl = window.location.origin;
export const timeout = 8000;

/**
 * @description: 请求服务创建工具函数
 * @param {object} param1
 * @return {*}
 */
export const createService = ({ responsePath }: { responsePath?: string }) => {
    const service = axios.create({
        baseURL,
        timeout
    });

    service.interceptors.request.use(config => ({
        ...config,
        headers: (() => {
            const accessToken = localStorage.getItem('access_token');
            if (accessToken) {
                return {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken}`
                };
            }
            return config.headers;
        })()
    }), error => {
        Promise.reject(error);
    });

    service.interceptors.response.use(
        response => responsePath ? get(response, responsePath) : response,
        error => {
            const { status, data } = error.response || {};
            const msg = data?.detail?.[0]?.msg ?? data?.detail;
            switch (status) {
                // 鉴权失败
                case 401:
                    const inRedirectList = redirectBlackList.find(path => window.location.pathname.startsWith(path));
                    if (!inRedirectList) {
                        window.location.href = `${localUrl}/login`;
                    }
                    if (data?.detail.includes('密码错误')) {
                        notification.warning({
                            message: data?.detail
                        });
                    }
                    console.error(`${status}: ${msg}`);
                    return Promise.reject(data);
                // 404业务逻辑上的数据不存在，不是接口不存在，也可能存在混用的情况，请注意判断
                case 404:
                    notification.error({
                        message: msg ?? `${error}`
                    });
                    return Promise.reject(error);
                // unknow
                case 409:
                    notification.error({
                        message: msg ?? `${error}`
                    });
                    return Promise.reject(error);
                // 后台提示报错
                case 422:
                    notification.error({
                        message: msg ?? `${error}`
                    });
                    return Promise.reject(error);
                case 403:
                    notification.error({
                        message: msg ?? `${error}`
                    });
                    return Promise.reject(error);
                // 近期账号密码错误次数过多
                case 429:
                    // TODO
                    break;
                case 500:
                    window.location.href = `${localUrl}/login`;
                    break;
                default:
                    // 取消请求暂时不提示错误 todo 加上是否显示提示的功能
                    // notification.error({
                    //     message: msg ?? `${error}`
                    // });
                    return Promise.reject(error);
            }
            notification.error({
                message: msg ?? `${error}`
            });
            return Promise.reject(data);
        }
    );

    return service;
};

export type Request<T, U> = (params: T) => Promise<U>;

